import { ThreeDotLoader } from "~/widgets/button";

export function CardsGettingReady() {
  return (
    <div class="fixed left-0 top-0 flex h-full w-full items-center justify-center">
      <div class="inline-flex h-fit w-[310px] flex-col items-center justify-center gap-5">
        <div class="h-6 w-12">
          <div class="h-fit w-fit">
            <ThreeDotLoader class="!bg-baseDark" />
          </div>
        </div>
        <div class="w-[150px] text-center font-['Inter'] text-[19px] font-semibold leading-relaxed text-basePrimaryDark">
          Your gift card is getting ready
        </div>
      </div>
    </div>
  );
}
