"use server";

import { getRequestEvent } from "solid-js/web";
import {
  getClientConfig,
  getCoinsSummary,
  getMinimalClientConfig,
} from "../apis/server_apis";
import { ClientConfig } from "../types/client";
import { getQuery } from "vinxi/http";
import { Mode, QueryParam } from "~/types";
import { CoinsSummaryResponse } from "../types/order";
import { ClientMinimalConfigOld } from "../types/brand";

export type BrandOrderRouteData = {
  clientConfig?: ClientConfig | ClientMinimalConfigOld;
  mode: Mode;
  isLoggedIn: boolean;
  coinsSummary?: CoinsSummaryResponse;
  clientId?: string;
};

export const getBrandOrderRouteData =
  async (): Promise<BrandOrderRouteData> => {
    let clientConfig$P:
      | Promise<ClientConfig | ClientMinimalConfigOld>
      | undefined;
    let coinsSummary$P: Promise<CoinsSummaryResponse> | undefined;

    const requestEvent = getRequestEvent();
    let mode: Mode = "hubble";
    let clientId: string | undefined;

    if (requestEvent) {
      const clientIdQuery = getQuery(requestEvent.nativeEvent)[
        QueryParam.ClientId
      ];
      if (clientIdQuery) {
        clientId = clientIdQuery as string;
        mode = "sdk";
      } else {
        mode = (requestEvent.locals.mode ?? "hubble") as Mode;
        if (mode === "rnr") {
          clientId = requestEvent.locals.clientId;
        }
      }
    }

    if (clientId) {
      if (mode === "sdk") {
        clientConfig$P = getClientConfig(clientId);
      } else if (mode === "rnr") {
        clientConfig$P = getMinimalClientConfig(clientId);
        coinsSummary$P = getCoinsSummary();
      }
    }

    const resolved = await Promise.all([
      clientConfig$P ?? Promise.resolve(undefined),
      coinsSummary$P ?? Promise.resolve(undefined),
    ]);

    let clientConfig = resolved[0];
    let coinsSummary = resolved[1];

    if (clientConfig == undefined || clientConfig?.partnerName === "hubble") {
      mode = "hubble";
    }

    return {
      clientConfig,
      isLoggedIn: getRequestEvent()?.locals.sid !== undefined,
      mode: mode,
      clientId: getRequestEvent()?.locals.clientId,
      coinsSummary,
    };
  };
